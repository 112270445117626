<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Sur la CCM illustrée ci-dessous, quel composé a le moins d’affinité pour la phase
        stationnaire? Quelle conclusion pouvons-nous faire concernant la polarite de cette molécule?
      </p>

      <p class="pl-12 mb-6">
        <v-img style="max-width: 105px" :src="imageName" />
      </p>

      <v-radio-group v-model="inputs.affinityAndPolarity" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q1Fr',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        affinityAndPolarity: null,
      },
      options: [
        {text: 'Composé  A - C’est le composé le moins polaire', value: 'AleastPolar'},
        {text: 'Composé  A - C’est le composé le plus polaire', value: 'AmostPolar'},
        {text: 'Composé  B - C’est le composé le moins polaire', value: 'BleastPolar'},
        {text: 'Composé  B - C’est le composé le plus polaire', value: 'BmostPolar'},
        {text: 'Composé  C - C’est le composé le moins polaire', value: 'CleastPolar'},
        {text: 'Composé  C - C’est le composé le plus polaire', value: 'CmostPolar'},
      ],
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('imageNumber').content;
    },
    imageName() {
      return '/img/assignments/Q1-TLC-V' + this.imageNumber.value + '.png';
    },
  },
};
</script>
